import React from "react"
import style from "./Project.module.css"
const Project = ({ data }) => {
  return (
    <div className={style.Project}>
      <h3>{data.name}</h3>
      <p>{data.description}</p>
      <p className={style.Stack}>
        <strong>Stack:</strong>
        <br></br>
        {data.stack}
      </p>
      <div className={style.Footer}>
        <a className={style.Github} href={data.github}>
          Github
        </a>
        {(data.url) ? <a href={data.url}>Open</a>:""}
      </div>
    </div>
  )
}

export default Project
