import React from 'react';
import data from "../../../content/data/projects.json";
import Project from './Project/Project.js';
import style from "./ProjectList.module.css";
const ProjectList = () => {
console.log(data.projects)
    const projects = data.projects.map((item,index)=>{
        return <Project data={item}></Project>
    });
    return (
        <div className={style.ProjectList}>
            {projects}
        </div>
    );
};

export default ProjectList;